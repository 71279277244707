@import "styles/colors.scss";

.mobile-navigation-container {

	position: fixed;
	z-index: 999;
	bottom: 0;
	left: 0;
	width: 100%;

	background: $persian_indigo;
	padding: 6px 5px 14px;

	.navigation {
		a {
			color: $white;
			
			svg.fill {
				* { fill: #fff; }
			}
		}

		a:hover {
			background: rgba( $white, .05 );
		}

		a.active {
			color: $lemon_ginger;
			background: rgba( $white, 0 );

			svg.fill {
				* { fill: $lemon_ginger; }
			}
		}
	}
}

@media (max-width: 530px) {
	.mobile-navigation-container {
		.navigation {
			font-size: 10px;
			
			a {
				min-width: 65px;
				min-height: 65px;
				margin: 0 3.5px;
			} 

			svg {
				max-height: 30px;
				max-width: 30px;
			}
		}
	}
}


@media (max-width: 374px) {
	.mobile-navigation-container {
		padding-bottom: 10px;

		.navigation {
			font-size: 9px;

			a {
				min-width: 55px;
				min-height: 55px;
			}

			svg {
				max-height: 27px;
				max-width: 27px;
			}
		}
	}
}