@import "styles/colors.scss";

.navigation {
	font-size: 1.285em;

	svg.logo-svg {
		max-width: 20px;
	}

	a {
		min-height: 87px;
		min-width: 85px;
		padding: 5px 8px 5px;

		margin: 0 5px;

		border: 1px solid transparent;
		border-radius: 8px;

		color: $persian_indigo;

		svg {
			margin-top: auto;
		}

		span {
			margin-top: auto;
		}
	}

	a:hover {
		background: rgba( $persian_indigo, .03 );
	}

	a.active {
		border-color: $medium_slate_blue;
		background: rgba( $persian_indigo, .1);
	}
}

@media (max-width: 1400px) {
	.navigation {
		font-size: 1.07em;
	}
}