$black: #000;
$black_perl: #141A1F;
$suva_grey :#8A8A8A;
$hit_grey: #9DAEAD;
$nepal: #9DAFBA;
$lynch: #72838D;
$pattens_blue: #C5D8E4;
$lemon_ginger: #9C8B23;
$madras: #433A08;
$paua: #171149;
$black_russian: #09061f;
$persian_indigo: #302682;
$medium_slate_blue: #7264E8;
$white: #fff;