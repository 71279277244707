@import "styles/colors.scss";

.external-link {
	display: inline-block;
	background: $lemon_ginger;
	color: $white;
	border-radius: 19px;
	padding: 6px 18px;

	&:hover {
		background: $black;
	}
}