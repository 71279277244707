.header {
	
	padding: 11px 0 10px;
	
	.container {
		max-width: 1393px;
	}

	.logo {
		margin-right: 20px;
	}

	.navigation {
		margin-left: auto;
	}

	.about-project-link {
		display: none;
	}
}


@media (max-width: 1060px ) {
	.header {
		.navigation {
			display: none;
		}

		.about-project-link {
			display: flex;
			margin-left: auto;
		}
	}
}

@media (max-width: 425px ) {
	.header .logo {
		font-size: 1.14em;
	}
}