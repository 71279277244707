@import "styles/colors.scss";

.about-project-link {
	
	color: $suva_grey;
	font-size: 1.14em;

	img {
		max-width: 49px;
	}

	span {
		margin-top: 10px;
	}
}