@import "./colors.scss";

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
}

.page-heading {
	font-weight: 500;
	font-size: 3.571em;
	margin-top: 0;
}

.page-subheading {
	font-weight: 600;
	color: $lemon_ginger;
	font-size: 2.5em;
}

.section-heading {
	font-weight: 400;
	margin-top: 0;
	font-size: 2.142em;
	margin-bottom: 30px;
	color: $persian_indigo;
}


@media (max-width: 1400px) {
	
	.page-heading {
		font-size: 3em;
	}
	
	.page-subheading {
		font-size: 2em;
	}

	.section-heading {
		font-size: 1.7em;
	}
}


@media ( max-width: 768px) {
	.section-heading {
		font-size: 1.42em;
	}
}

@media (max-width: 600px ) {
	.page-heading {
		font-size: 2em;
	}
}