@import "styles/colors.scss";

.footer {
	.container {
		max-width: 1393px;
	}
}

.footer__top {
	background-color: $persian_indigo;
	color: $white;
	min-height: 57px;	

	.container {
		padding: 6px 15px 10px 20px;

		background-repeat: no-repeat;
		background-position: 0 50%;
	}
}


.footer__top_search {
	.external-link {
		margin-left: 22px;
	}

	strong {
		font-weight: 500;
		color: $lemon_ginger;
		font-size: 1.428em;
		display: block;
	}
}

.footer__top_socials {
	margin-left: auto;

	.social-media {
		margin: 0 -4px;

		a {
			margin: 0 4px;
		}
	}

	span {
		margin-right: 12px;
	}
}

.footer__logos {
	padding: 40px 15px 30px;
	max-width: 880px;
	margin: 0 auto;
	min-height: 120px;

	img {
		width: auto;
		max-width: none;
		max-height: 50px;
	}
}