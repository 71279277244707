
.f-r {
	display: flex;
	flex-wrap: wrap;
}

.f-c {
	display: flex;
	flex-direction: column;
}

.f-ac {
	display: flex;
	align-items: center;
}

.f-as {
	display: flex;
	align-items: flex-start;
}

.f-ac-jc {
	display: flex;
	align-items: center;
	justify-content: center;
}

.f-ac-js {
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.f-ae {
	display: flex;
	align-items: flex-end;
}

.f-ac-jsb {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.f-c-ac {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.f-c-je {
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.f-c-ac-jc {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}