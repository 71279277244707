@import "./colors.scss";
@import "./headings.scss";
@import "./flex.scss";

a {
	text-decoration: none;
	transition: color .4s, background .4s, border-color .4s;
}

a, button {
	&:focus-visible {
		outline: 4px solid red;
	}
}

img, svg {
	max-width: 100%;
	height: auto;
}

p {
	margin-top: 0;
	margin-bottom: 17px;
}

section {
	padding-top: 23px;
	padding-bottom: 42px;
}

.section-head {
	margin-bottom: 30px;

	.section-heading {
		margin-bottom: 0;
	}

	.see-all-button {
		margin-left: auto;
	}
}

.has-scrollbar {
	scrollbar-width: thin;
	scrollbar-color: $paua #f2f2f2;

	&::-webkit-scrollbar {
		width: 8px;
	}
	
	&::-webkit-scrollbar-track {
		background: #f2f2f2;
	}
	
	&::-webkit-scrollbar-thumb {
		background-color: $paua;
		outline: none;
	}
}

.container {
	max-width: 1164px;
	padding: 0 15px;
	margin: 0 auto;
}

.d-none {
	display: none;
}

.with-overlay {
	position: relative;

	&::before {
		content: '';
		display: block;
		position: absolute;
		z-index: 1;
		width: 100%;
		height: 100%;
	}
}

.social-media {
	padding: 0;
	list-style: none;

	a {
		display: flex;
		width: 29px;
		height: 29px;
		
		background: #fff;
		border-radius: 4px;

		svg {
			margin: auto;
			max-width: 70%;
			max-height: 70%;
		}
	}
}

@media (max-width: 1060px) {
	section .container {
		padding-right: 0;
	}

	section .section-head {
		padding-right: 15px;
		margin-bottom: 8px;
	}
}

@media (max-width: 600px ) {
	section {
		padding-top: 18px;
		padding-bottom: 25px;
	}
}