* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-size: 14px;
	font-family: "Zilla Slab", serif;
}

#chopin-app {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
}

main {
	flex: auto;
	display: flex;
	flex-direction: column;
}